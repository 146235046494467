import * as React from "react"
import { Link } from "gatsby"
import { timeFormatter } from "../../../services/timeHelper"
import TimeAgo from "timeago-react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Moment from "react-moment"
import AxiosInstance from "../../../services/axiosInstance"
import TagListView from "./tagListView"
import { useState } from "react"
import moment from "moment"
import { AGENDA_DATE_FORMAT } from "../eventHelper"

const AgendaTable = ({ data }) => {
  const defaultContactImage = "StaticImages/avatar.gif"
  const onDelete = id => {
    if (!window.confirm("Delete this event?")) {
      return false
    }
    return new Promise(async () => {
      await AxiosInstance.delete(`/calendar/` + id).then(response => {
        window.location.reload()
        return response
      })
    })
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="agenda" role="tabpanel" aria-labelledby="agenda-tab">
            <table id="tblAgenda" className="table dataTable" cellSpacing="0" width="100%">
              <thead>
                <tr>
                  <th width="5%">&nbsp;</th>
                  <th width="70%">Upcoming Agenda Events</th>
                  <th width="15%" className="d-none d-md-table-cell">
                    Date/Time
                  </th>
                  <th width="10%" className="text-end d-none d-lg-table-cell">
                    Action
                  </th>
                </tr>
                <tr className="d-none table_action_container">
                  <td colSpan="4" className="text-left">
                    <div className="batch-action-container">
                      <button className="btn btn-trash btnMoveToTrash">
                        <i className="bi bi-trash-fill" /> Move to Trash
                      </button>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.length > 0 &&
                  data.map((event, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-center tableColChkDel">
                          <div className="tableColChkDelBox">
                            <input
                              type="checkbox"
                              name="fileid[]"
                              id={`C_ID${event.id}`}
                              className="css-checkbox"
                            />
                            <label htmlFor={`C_ID${event.id}`} className="css-checkbox-label" />
                            {event.task ? (
                              <span
                                className="tableColChkSpan transactionTypeIconColor"
                                style={{
                                  color: event.task.transactionType?.iconColor,
                                  backgroundColor: event.task.transactionType?.iconBackgroundColor,
                                }}
                              >
                                <i
                                  className={`bi bi-${
                                    event.task.transactionType
                                      ? event.task.transactionType.icon
                                      : "check-square"
                                  }`}
                                />
                              </span>
                            ) : (
                              <span className="tableColChkSpan btn-secondary">
                                <i className="bi bi-calendar3" />
                              </span>
                            )}
                          </div>
                        </td>
                        <td>
                          {event.task ? (
                            <>
                              <Link to={`/jobs/detail/${event.task?.id}`}>{event.title}</Link> &nbsp;
                              {event.task.tags.length > 0 && (
                                <TagListView tags={event.task.tags} taskId={event.task.id} />
                              )}
                              {event.task.taskContacts.length > 0 && (
                                <ul className="user-card-list ms-1">
                                  {event.task.taskContacts.map(
                                    (taskContactData, taskContactIndex) =>
                                      taskContactData.contact.name && (
                                        <li key={taskContactIndex}>
                                          <Link to={`/contacts/detail/${taskContactData.contact?.id}`}>
                                            <img
                                              src={`${process.env.GATSBY_WRANGLER_URL}${
                                                taskContactData.contact?.profilePic
                                                  ? taskContactData.contact.profilePic
                                                  : defaultContactImage
                                              }`}
                                              alt=""
                                              className="user_avatar"
                                            />
                                            {`${taskContactData.contact?.name}${
                                              taskContactData.settingRole
                                                ? `, ${taskContactData.settingRole.role}`
                                                : ""
                                            }`}
                                          </Link>
                                        </li>
                                      )
                                  )}
                                </ul>
                              )}
                              {event.task?.metaJson?.money_details?.single_price && (
                                <div className="SwiftCloudMoneyTags">
                                  <ul>
                                    <li className="CompanyOnly Everyone tag-blue">
                                      <a>${event.task.metaJson.money_details.single_price}</a>
                                    </li>
                                  </ul>
                                </div>
                              )}
                              {event.task?.metaJson?.location && (
                                <span
                                  className="btn btn-sm btn-success ms-2"
                                  onClick={() => {
                                    window.open(
                                      `https://www.google.com/maps/dir//${event.task?.metaJson?.location}`,
                                      "_blank"
                                    )
                                  }}
                                >
                                  <i className="bi bi-geo-alt-fill" />
                                </span>
                              )}
                            </>
                          ) : (
                            <Link to={`/calendar/detail/${event.id}`}>{event.title}</Link>
                          )}
                          &nbsp;
                          {event.metaJson.event_tags?.length > 0 && (
                            <TagListView tags={event.metaJson.event_tags} eventId={event.id} />
                          )}
                        </td>

                        <td className="d-none d-md-table-cell">
                          <OverlayTrigger
                            overlay={
                              <Tooltip>
                                <Moment format="dddd, MMMM D, YYYY @ h:mm a">{event.startTime}</Moment>
                              </Tooltip>
                            }
                          >
                            <span>{moment(event.startTime).format(AGENDA_DATE_FORMAT)}</span>
                          </OverlayTrigger>
                        </td>

                        <td className="text-end d-none d-lg-table-cell">
                          {/* <div className="lastActivityBox">
                            <TimeAgo datetime={timeFormatter(event.updatedAt)} />
                          </div> */}
                          <div className="statusActionBox">
                            <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                              <a className="btn btn-delete" onClick={() => onDelete(event.id)}>
                                <i className="bi bi-trash-fill" />
                              </a>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgendaTable
