import * as React from "react"
import { useEffect, useRef, useState } from "react"
import clsx from "clsx"
import moment from "moment"

import Layout from "../../Layout/layout"
import Seo from "../../seo"
import AxiosInstance from "../../../services/axiosInstance"
import EventModal from "./../eventModal"
import MenuContainer from "../menuContainer"
import AgendaTable from "./agendaTable"
import AgendaLazyLoad from "./agendaLazyLoad"

const Agenda = ({ addEvent }) => {
  const fromDate = moment().format("YYYY-MM-DD")
  const triggerRef = useRef(null)
  const [showModal, setShowModal] = useState(addEvent)
  const [selectedEventTime, setSelectedEventTime] = useState("")

  const onGrabData = (currentPage, pages_count) => {
    return new Promise(async resolve => {
      let data = {
        items: [],
        pages_count: pages_count,
      }

      if (pages_count === null || currentPage <= pages_count) {
        data = await AxiosInstance.get(`/calendar?from_date=${fromDate}&page=${currentPage}`).then(
          response => {
            return response.data
          }
        )
      }
      resolve(data, pages_count)
    })
  }

  const { data, loading } = AgendaLazyLoad({ triggerRef, onGrabData })

  return (
    <Layout>
      <Seo title="Calendar" />
      <div id="right-section" className="h-100">
        <div className="row g-0">
          <div className="col" id="page-urls">
            <MenuContainer setShowModal={setShowModal} page="agenda" />
            <AgendaTable data={data} />
          </div>
          {showModal && (
            <EventModal
              showModal={showModal}
              setShowModal={setShowModal}
              selectedEventTime={selectedEventTime}
            />
          )}
        </div>
        <div ref={triggerRef} className={clsx("trigger", { visible: loading })} />
      </div>
    </Layout>
  )
}

export default Agenda
