import * as React from "react"

import PrivateRoute from "../../components/privateRoute"
import ReduxWrapper from "../../redux/reduxWrapper"
import Agenda from "../../components/Calendar/Agenda/agenda"

const AgendaPage = props => {
  return <PrivateRoute component={Agenda} location={props.location} />
}

const WrappedPage = props => <ReduxWrapper element={<AgendaPage {...props} />} />
export default WrappedPage
